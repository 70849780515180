import MechaIconSvg from "../../assets/svg/MechaIconSvg";

interface HeaderProps {
  headerTitle?: string;
}

const Header = ({ headerTitle }: HeaderProps) => {
  return (
    <div className="lg:w-full lg:mx-auto flex flex-col justify-center items-center mb-2 lg:mb-[10px]">
      <p className="lg:text-[32px] xs:text-[30px] text-center font-bold  text-neutral-150 dark:text-white">
        {headerTitle}
      </p>
    </div>
  );
};
export default Header;
