import React from "react";
import { footerLinks } from "../../config/constants";

interface IFooterLinksComponentProps {
  navigations?: { title: string; redirectUrl: string }[];
}
const FooterLinksComponent = (props: IFooterLinksComponentProps) => {
  const navigations = props.navigations || footerLinks;

  return (
    <div className="flex flex-row h-12 items-center justify-between text-sm w-96 font-normal  text-[#646D8A]  dark:text-custom-420  mx-auto">
      <ul className="flex justify-between w-full px-5 md:px-0">
        {navigations.map((navigation, index) => (
          <li key={index}>
            <a href={navigation.redirectUrl} target="_blank" rel="noreferrer">
              {navigation.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterLinksComponent;