import Header from "./Header";
import React from "react";
import FooterLinksComponent from "./NavigationComponent";
import { cn } from "../../utils/tailwindHelper";
type Props = {
  children?: any;
  title?: string;
  headerTitle?: string;
  backgroundImageUrl?: string;
  isErrorPage?: boolean;
};

const Layout = ({
  children,
  title = "This is the default title",
  headerTitle,
  backgroundImageUrl,
  isErrorPage = false,
}: Props) => (
  <>
    <div
      className={`font-space-grotesk flex flex-col justify-center text-neutral-500 items-center dark:text-white min-h-screen bg-repeat-x relative`}
      style={{
        backgroundImage: backgroundImageUrl
          ? `url(${backgroundImageUrl})`
          : `url("/assets/Banner.png")`, // Replace with the direct image URL
        backgroundSize: "unset",
        backgroundPosition: "top",
        // backgroundRepeat: "no-repeat",
        // backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className={`w-full flex items-center justify-center${
          title === "Mecha Sign Up" ? " py-8" : ""
        }`}
      >
        <div
          className={cn(
            "flex flex-col w-[360px] lg:w-[450px]  px-5 py-10 md:px-8 md:py-12 rounded-2xl md:border  items-center justify-center dark:bg-dark-800 bg-light-800 dark:border-dark-400 border-light-400",
            {
              "dark:border-dark-r-560 border-dark-r-560": isErrorPage,
            },
          )}
        >
          <Header headerTitle={headerTitle} />
          {children}
        </div>
      </div>
      <div className="absolute bottom-0">
        <FooterLinksComponent />
      </div>
    </div>
  </>
);

export default Layout;
